/* Override styles globally for input text fields */
label {
  display: block !important; 
  color: #000 !important;
  font-size: 12px !important;
  font-family: AvenirNextCyr !important;
}
.p-inputtext {
  color: #000 !important;
  font-size: 12px !important;
  height: 28px !important;
}

.p-dropdown-label {
  line-height: 25px !important;
}
.pi-cog {
  color: #030927 !important;
}
.p-checkbox-label {
  padding-left: 7px !important;
  padding-top: 5px !important;
}
.p-dropdown {
  height: 28px !important;
  line-height: 14px;  
}
.p-inputtext:focus{
  box-shadow:none !important;
  -webkit-box-shadow:none !important;
  -moz-box-shadow:none !important;
}
.p-datatable-table .p-datatable-thead > tr > th {
  font-family: AvenirNextCyr !important ;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  font-family: AvenirNextCyr;
}
/* Override styles for checkbox */
 .p-checkbox-box {
  border: 2px solid #ced4da !important;
  background: #ffffff !important;
  width: 16px !important;
  height: 16px !important;
  color: #495057 !important;
  border-radius: 4px !important;
  margin-top: 3px !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

 .p-checkbox .p-checkbox-box {
  width: 16px !important;
  height: 16px !important;
  margin-top: 3px !important;
}

 .p-checkbox .p-checkbox-box:hover {
  border: 2px solid rgba(236, 155, 27, 1) !important;
  background: rgba(236, 155, 27, 1) !important;
  color: #ffffff !important;
}

 .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #030E4C !important;
}
.p-checkbox-icon {
  transition-duration: 0.2s !important;
  color: #ffffff !important;
  font-size: 14px !important;
}
.p-highlight {
  border-color: #030e4c !important;
  background: #030e4c !important;
}
.p-focus {
  outline: 0 none !important;
  outline-offset: 0 !important;
  border-color: #030e4c !important;
}
.p-highlight:hover {
  border-color: rgba(236, 155, 27, 1) !important;
  background: rgba(236, 155, 27, 1) !important;
  color: #ffffff !important;
}
 .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: rgba(236, 155, 27, 1) !important;
  background: rgba(236, 155, 27, 1) !important;
  color: #ffffff !important;
}
.p-datatable-thead > tr > th {
  padding: 7px 7px !important;
  background-color:#f1f1f1 !important;
  color: #030e4c !important;
  font-weight: 600 !important;
}
.p-datatable-tbody > tr > td{
  padding: 5px 5px !important;
  border-width:1px 0px 1px 0px !important;
}

.pi-search{
  color: #030e4c !important;
  font-weight: 600;
  font-size: 13px !important;
  margin-left: 7px !important;
}
.pi-trash{
  padding-left: 5px !important;
  font-size: 13px !important;
}
.pi-cog{
  font-size: 16px !important;
}

/* Override button label styles */
 .p-button .p-button-label {
  font-size: 12px !important;
}

/* Override datatable header styles */
 .p-datatable .p-datatable-thead>tr>th {
  font-weight: 600 !important;
  color: #030e4c !important;
  background: #f1f1f1 !important;
  font-family: AvenirNextCyr !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px !important;
}

/* Override datatable body cell styling */
 .p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  padding: 0px !important;
  padding-left: 12px !important;
}

.p-dialog-header{
  padding-top: 15px;
  padding-bottom: 15px;
} 
.p-dialog-content{
  padding-bottom: 15px;
}